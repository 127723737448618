.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    padding: 40px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}
